import axios from 'axios';

export default {
  namespaced: true,
  state: {
    excursions: [],
    excursion: null
  },
  mutations: {
    setExcursions(state, excursions) {
      state.excursions = excursions;
    },
    setExcursion(state, excursion) {
      state.excursion = excursion;
    }
  },
  actions: {
    loadExcursions({ commit }) {
      axios.get('/api/excursions').then(function(response) {
        commit('setExcursions', response.data);
      });
    },
    loadExcursion({ commit }, slug) {
      return axios.get(`/api/excursions/${slug}`).then(function(response) {
        commit('setExcursion', response.data);
      });
    }
  }
};
