<template>
  <button class="button" :class="additionalClass" @click="$emit('click', $event)"><slot></slot></button>
</template>

<script>
  export default {
    props: ['type'],
    computed: {
      additionalClass() {
        if (this.type === 'outlined') {
          return 'button--outlined';
        }
        return null;
      }
    }
  };
</script>

<style scoped lang="scss">
  .button {
    font-family: Montserrat, "Roboto Light";
    height: 48px;
    font-size: 1.125rem;
    border: none;
    padding: 10px;
    width: 148px;
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    background: #000000;
    color: #FFFFFF;

    &:hover {
      box-shadow: none;
      opacity: 0.8;
    }

    &.button--outlined {
      background: #FFFFFF;
      border: 2px solid #000000;
      color: #000000;
    }
  }
</style>
