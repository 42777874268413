<template>
  <Popup v-if="isShownPopup" title="Кошик" width="600" @close="closePopup">
    <template v-if="orderItems.length === 0">
      <div class="cart-empty__title">Кошик порожній</div>
      <div class="cart-empty__text">
        Ви можете обрати книги <a @click="goToBooks">на цій сторінці</a>
      </div>
    </template>
    <template v-if="orderItems.length > 0 && booksHash">
      <div class="cart-popup__content">
        <OrderItems :books-hash="booksHash"/>
      </div>
      <hr>
      <div class="cart-popup__total">
        <span class="cart-popup__total-text">Всього:</span>
        <span class="cart-popup__total-price">
          {{ totalCartPrice }} <span class="cart-popup__total-currency">грн</span>
        </span>
      </div>
    </template>
    <template #footer>
      <div class="cart-popup__footer">
        <a @click="goToBooks">Обрати ще</a>
        <router-link v-slot="{ navigate }" :to="{ name: 'cart' }" custom>
          <Button @click="closePopup(); navigate($event);">Оформити</Button>
        </router-link>
      </div>
    </template>
  </Popup>
</template>

<script>
  import axios from 'axios';
  import { createNamespacedHelpers } from 'vuex';
  import { reduce } from 'lodash';

  import Popup from 'application/shared/_components/Popup';
  import Button from 'application/shared/_components/Button';
  import OrderItems from './OrderItems';

  const { mapState, mapMutations } = createNamespacedHelpers('cart');

  export default {
    components: { OrderItems, Popup, Button },
    data() {
      return {
        booksHash: null
      };
    },
    computed: {
      ...mapState({
        isShownPopup: (state) => state.isShownPopup,
        orderItems: (state) => state.orderItems
      }),
      totalCartPrice() {
        return reduce(this.orderItems, (total, orderItem) => {
          return total + this.booksHash[orderItem.bookId]?.price * orderItem.count;
        }, 0);
      }
    },
    mounted() {
      const booksHash = {};
      axios.get('/api/books').then((response) => {
        response.data.forEach((book) => {
          booksHash[book.id] = book;
        });
        this.$nextTick(() => {
          this.booksHash = booksHash;
        });
      });
    },

    methods: {
      ...mapMutations(['closePopup']),
      goToBooks() {
        this.closePopup();
        this.$router.push({ name: 'books' }).catch(() => {});
      }
    },
  };
</script>

<style scoped lang="scss">
  .cart-empty__title {
    margin-bottom: 12px;
  }

  a {
    border-bottom: 1px solid black;
    cursor: pointer;

    &:hover {
      border-bottom: 4px solid #fbff00;
    }
  }

  hr {
    width: 100%;
    margin-bottom: 0;
  }

  .cart-popup__content {
    max-height: calc(100vh - 400px);
    overflow: auto;
    margin-bottom: 20px;
  }

  .cart-popup__total {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-top: 20px;

    .cart-popup__total-text {
      opacity: 0.6;
      margin-right: 8px;
    }

    .cart-popup__total-price {
      font-size: 1.5rem;
      font-weight: 500;

      .cart-popup__total-currency {
        font-size: 1rem;
      }
    }
  }

  .cart-popup__footer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
</style>
