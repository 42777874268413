<template>
  <div>
    <div v-for="orderItem in orderItems" :key="orderItem.bookId" class="order-item">
      <div class="book-image">
        <img :src="booksHash[orderItem.bookId].image.url" alt="">
      </div>
      <div class="book-info">
        <span class="book-title">{{ booksHash[orderItem.bookId].title }}</span>
        <div class="management">
          <div class="book-price-block">
            <span class="book-price">
              {{ booksHash[orderItem.bookId].price }} <span class="book-price-currency">грн</span>&nbsp;x&nbsp;
            </span>
            <span class="book-count">{{ orderItem.count }}</span>
          </div>
          <div class="management-count">
            <span class="management-button" @click="increaseCount(orderItem)">+</span>
            <span class="management-button" @click="() => onMinusClick(orderItem)">-</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';

  const { mapState, mapMutations } = createNamespacedHelpers('cart');

  export default {
    props: ['booksHash'],
    computed: {
      ...mapState({
        orderItems: (state) => state.orderItems
      })
    },
    methods: {
      ...mapMutations(['increaseCount', 'decreaseCount', 'removeItem']),
      onMinusClick(orderItem) {
        if (orderItem.count <= 1) {
          if (confirm('Ви впевнені, що хочете прибрати дану книгу з кошика?')) {
            this.$nextTick(() => {
              this.removeItem(orderItem);
            });
          }
        } else {
          this.decreaseCount(orderItem);
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .order-item {
    display: flex;
    height: 200px;

    .book-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      margin-right: 8px;

      img {
        max-height: 200px;
        max-width: 200px;
        width: auto;
        height: auto;
      }
    }

    .book-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      .book-title {
        font-weight: 500;
        margin-bottom: 40px;
        font-size: 1.2rem;
      }

      .book-price-block {
        display: flex;
        align-items: baseline;
        line-height: 0;
        margin-right: 8px;

        .book-price {
          opacity: 0.6;

          .book-price-currency {
            font-size: 0.75rem;
          }
        }

        .book-count {
          font-size: 1.5rem;
          width: 24px;
        }
      }
    }

    .management {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;

      .management-count {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.5rem;
        width: 28px;

        border: 1px solid #D8D8D8;
        border-radius: 4px;
      }

      .management-button {
        cursor: pointer;
        width: 100%;
        text-align: center;
        user-select: none;

        &:hover {
          opacity: 0.8;
          background-color: #D8D8D8;
        }
      }
    }
  }
</style>
