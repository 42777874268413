import Vue from 'vue';
import Router from 'vue-router';

import { MainRoutes } from './main/routes';
import { BooksRoutes } from './books/routes';
import { ExcursionsRoutes } from './excursions/routes';
import { CartRoutes } from './cart/routes';
import { CourseRoutes } from './course/routes';

const routes = [
  ...MainRoutes,
  ...BooksRoutes,
  ...ExcursionsRoutes,
  ...CartRoutes,
  ...CourseRoutes
];

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
