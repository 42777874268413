<template>
  <div class="popup">
    <div class="popup-overlay" @click="closePopup"></div>
    <div class="popup-modal" :style="{ width: `${(width || 500)}px` }">
      <div class="popup-header">
        <div class="popup-title">{{ title }}</div>
        <div class="popup-close-icon" @click="closePopup">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L17 17" stroke="black" stroke-width="2" stroke-linecap="round"/>
            <path d="M1 17L17 1" stroke="black" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
      <hr>
      <div class="popup-message"><slot></slot></div>
      <slot name="footer">
        <Button class="popup-button" @click="closePopup">OK</Button>
      </slot>
    </div>
  </div>
</template>

<script>
  import Button from 'application/shared/_components/Button';

  export default {
    components: { Button },
    props: ['title', 'width'],
    methods: {
      closePopup() {
        this.$emit('close');
      }
    }
  };
</script>

<style scoped lang="scss">
  .popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    top: 0;
    left: 0;

    .popup-overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .popup-modal {
      background-color: white;
      max-width: 80%;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      padding: 24px;

      .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .popup-title {
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 28px;
        }

        .popup-close-icon {
          cursor: pointer;
        }
      }

      hr {
        width: 100%;
        margin: 0;
      }

      .popup-message {
        margin-top: 20px;
        font-weight: 400;
        font-size: 1rem;
      }

      .popup-button {
        margin-top: 20px;
        float: right;
        width: 114px;
      }
    }
  }
</style>
