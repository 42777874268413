import { without } from 'lodash';

const getStoredOrderItems = () => {
  try {
    if (localStorage.orderItems) {
      return JSON.parse(localStorage.orderItems);
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

const storeOrderItems = ({ orderItems }) => {
  localStorage.orderItems = JSON.stringify(orderItems);
};

export default {
  namespaced: true,
  state: {
    isShownPopup: false,
    orderItems: getStoredOrderItems()
  },
  mutations: {
    showPopup(state) {
      state.isShownPopup = true;
    },
    closePopup(state) {
      state.isShownPopup = false;
    },
    addBook(state, book) {
      const foundOrderItem = state.orderItems.find((orderItem) => orderItem.bookId === book.id);
      if (foundOrderItem) {
        foundOrderItem.count += 1;
      } else {
        state.orderItems.push({ bookId: book.id, count: 1});
      }
      storeOrderItems(state);
    },
    increaseCount(state, orderItem) {
      orderItem.count += 1;
      storeOrderItems(state);
    },
    decreaseCount(state, orderItem) {
      orderItem.count -= 1;
      storeOrderItems(state);
    },
    removeItem(state, orderItem) {
      state.orderItems = without(state.orderItems, orderItem);
      storeOrderItems(state);
    }
  },
  actions: {}
};
