<template>
  <div class="app">
    <Header></Header>
    <router-view class="app__content"></router-view>
    <Footer></Footer>
    <CartPopup></CartPopup>
  </div>
</template>

<script>
  import Header from './_components/Header';
  import Footer from './_components/Footer';
  import CartPopup from 'application/cart/_components/Popup';

  export default {
    components: {
      Header,
      Footer,
      CartPopup
    },
    metaInfo: {
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    }
  };
</script>

<style lang="scss">
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    overflow-y: scroll
  }

  p {
    margin: 0;
  }

  .app {
    font-family: Montserrat, "Roboto Light";
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .app__content {
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      flex: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
</style>
