import Vue from 'vue';

import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

import App from '../application/Module';
import router from '../application/routes';
import store from '../application/store';

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
  });
});
