import Vue from 'vue';
import Vuex from 'vuex';

import main from './main/store';
import books from './books/store';
import excursions from './excursions/store';
import cart from './cart/store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main,
    books,
    excursions,
    cart
  }
});
