import axios from 'axios';

export default {
  namespaced: true,
  state: {
    books: [],
    book: null
  },
  mutations: {
    setBooks(state, books) {
      state.books = books;
    },
    setBook(state, book) {
      state.book = book;
    }
  },
  actions: {
    loadBooks({ commit }) {
      axios.get('/api/books').then(function(response) {
        commit('setBooks', response.data);
      });
    },
    loadBook({ commit }, slug) {
      axios.get(`/api/books/${slug}`).then(function(response) {
        commit('setBook', response.data);
      });
    }
  }
};
